<template>
    <v-row>
        <v-col cols="12">
            <v-btn @click="salvar" fixed bottom right fab color="primary"><v-icon dark>mdi-content-save</v-icon></v-btn>
            <v-card>
                <v-toolbar color="primary" dark flat height="auto">
                    <v-toolbar-title class="py-2">Informações principais</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="12" sm="6"><SelectFranquia hide-details label="* Franquia:" v-model="registro.id_franquia" /></v-col>
                            <v-col cols="12" sm="6"><v-text-field hide-details label="* CPF:" v-model="registro.cpf" v-mask="'###.###.###-##'" /></v-col>
                        </v-row>
                    </v-form>
                    <v-alert v-if="erro" type="error" class="mt-5"><div v-html="erro"></div></v-alert>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from 'axios'
import {mask} from 'vue-the-mask'
import {mapState} from 'vuex'
import SelectFranquia from "@/Views/Estrutura/Form/SelectFranquia.vue";

export default {
    name: "AlunoImportar",
    components: {SelectFranquia},
    directives : {mask},
    data() {
        return {
            registro : {},
            erro : null,

            selectFranquia : [],
            selectTurma : []
        }
    },
    computed : {
        ...mapState(['apiUrl'])
    },
    methods : {
        salvar() {
            axios.post(`${this.apiUrl}aluno/importar`, {
                cpf : this.mascaraRemover(this.registro.cpf),
                id_franquia : this.registro.id_franquia
            }).then( (res) => {
                if(res.data.erro == true) {
                    this.erro = res.data.mensagem
                } else {
                    this.erro = null
                    this.$router.push({path : `/aluno/gerenciar/${res.data}`})
                }
            }).catch(e => {
                this.erro = this.erroFormatar(e)
            })
        },
        async init() {
            this.registro = {}
        }
    },
    watch : {
        'registro.id_franquia' : function () {
            this.selectTurmaListar().then(() => {
                this.registro.id_turma = null
            })
        }
    },
    activated : function() {
        this.init()
    }
}
</script>

<style scoped>

</style>
